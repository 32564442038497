import { FC } from 'react';

import ReadyIcon from 'svg/DocumentStatuses/ReadyIcon';
import { ISignerOnFinalPage } from 'types/SendDocuments';
import { getFullSignerName } from 'utils/sendDocumentsHelpers';

const SignerOnFinalPage: FC<ISignerOnFinalPage> = ({
  signer,
}) => (
  <div className="container px-0 py-3 send-documents-final-container">
    <div className="row mx-0 align-items-center">
      <div className="col-1 p-0">
        <ReadyIcon />
      </div>
      <div className="col-11">
        <div className="row">
          <div className="col-12 send-documents-final-name">
            {getFullSignerName(signer)}
          </div>
        </div>
        <div className="row send-documents-final-email">
          <div className="col-12">
            {signer.email}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SignerOnFinalPage;