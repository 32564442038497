import { FC } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Button from 'components/Base/Button';
import { CANCEL, SAVE } from 'constants/general';

interface IManagementModal {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  saveButtonText?: string;
  withCancelButton?: boolean;
  disableSaveButton?: boolean;
  centered?: boolean;
}

const ManagementModal: FC<IManagementModal> = ({
  title,
  isOpen,
  onSave,
  onClose,
  saveButtonText = SAVE,
  withCancelButton = true,
  disableSaveButton = false,
  centered = false,
  children,
}) => (
  <Modal centered={centered} isOpen={isOpen}>
    <ModalHeader toggle={disableSaveButton ? undefined : onClose}>
      {title}
    </ModalHeader>
    <ModalBody>
      {children}
    </ModalBody>
    <ModalFooter>
      {
        withCancelButton && (
          <Button
            classes="outline"
            onClick={onClose}
            disabled={disableSaveButton}
          >
            {CANCEL}
          </Button>
        )
      }
      <Button onClick={onSave} disabled={disableSaveButton}>
        {saveButtonText}
      </Button>
    </ModalFooter>
  </Modal>
);

export default ManagementModal;