import { FC, memo } from 'react';

import { FormGroup, Input, Label } from 'reactstrap';

import FieldWithButtonWrapper from 'components/Editor/EditorDevTools/FieldWithButtonWrapper';
import { ATTACHMENT_DEFAULT_PARAMS, ATTACHMENT_FIELD } from 'constants/editors';
import { useRolePermission } from 'hooks/useRolePermission';
import { IRenderField, ISelectFieldType } from 'types/Properties';
import { createNameConstant } from 'utils/createNameConstant';

import 'scss/components/_propertyTab.scss';

const FieldProperties: FC<IRenderField<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>> = ({
  label,
  type,
  placeholder,
  value,
  options,
  checked,
  disabled = false,
  onChangeHandler,
  onKeyDown,
  onClickHandler,
  button,
  numberLimits = {
    minValue: null,
    maxValue: null,
  },
}) => {
  const { permissionUsingAttachments } = useRolePermission();
  const propertyName = createNameConstant(label);
  const isCheckboxType = (type === 'checkbox');
  const numberFieldProperties = (type === 'number') && {
    min: numberLimits.minValue ?? ATTACHMENT_DEFAULT_PARAMS.limit,
    max: numberLimits.maxValue ?? ATTACHMENT_DEFAULT_PARAMS.maxLimit,
    step: 1,
  };

  const inputTextClasses = (type === 'select') ? 'text-capitalize' : '';

  return (
    <FormGroup
      check={isCheckboxType}
      className={`${isCheckboxType ? 'form-group flex-column-reverse' : ''}`}
    >
      <Label check={isCheckboxType} for={propertyName} className="mb-0">{label}</Label>
      <FieldWithButtonWrapper withButton={!!button?.text}>
        <Input
          className={inputTextClasses}
          id={propertyName}
          placeholder={placeholder || ''}
          type={type}
          value={value || ''}
          checked={checked}
          disabled={disabled}
          onChange={onChangeHandler}
          onKeyDown={onKeyDown}
          onClick={onClickHandler}
          {...numberFieldProperties}
        >
          {
            options && options.map(({ id, label, type, disabled = false }: ISelectFieldType) => {
              if (type === ATTACHMENT_FIELD && !permissionUsingAttachments) return null;
              return (
                <option
                  key={`${id}${type}`}
                  data-key={id}
                  value={type}
                  disabled={disabled}
                >
                  {label}
                </option>
              );
            })
          }
        </Input>
        {
          button?.handler
          && (
            <button
              type="button"
              className="ms-2 property-tab-field-button"
              onClick={button.handler}
            >
              {button.text}
            </button>
          )
        }
      </FieldWithButtonWrapper>
    </FormGroup>
  );
};

export default memo(FieldProperties);