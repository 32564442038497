import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import SimpleSpinner from 'components/Base/SimpleSpinner';
import Tooltip from 'components/Tooltip';
import useChangeDocumentInCollection from 'hooks/signNow/useChangeDocumentInCollection';
import { setSelectedFieldKey } from 'store/actions/pdfTemplates';
import { RootStateType } from 'store/reducers';
import { PDFFieldType } from 'types/PdfTemplates';
import { ISignNowDocument } from 'types/signNow/store';

export const ELEMENT_FOR_COLLECTION_SLIDERS = 'element-for-collection-sliders';

const useCollectionSlidersInSignNow = (
  documents: ISignNowDocument[],
) => {
  const dispatch = useDispatch();
  const {
    pdfTemplateKey,
    pdfTemplateFields,
    selectedFieldKey,
  } = useSelector((state: RootStateType) => state.pdfTemplates);
  const onChangeDocumentInCollection = useChangeDocumentInCollection();
  const [offsetRight, setOffsetRight] = useState<number>(0);
  const [offsetTop, setOffsetTop] = useState<number>(0);
  const [activeFileKey, setActiveFileKey] = useState<string | null>(null);

  const getRightAndTopOffsets = useCallback(() => {
    const pdfEditorMainComponent = document.getElementById(ELEMENT_FOR_COLLECTION_SLIDERS);
    if (pdfEditorMainComponent) {
      const rect = pdfEditorMainComponent.getBoundingClientRect();
      if (!offsetRight || offsetRight !== rect.right) {
        setOffsetRight(rect.right);
      }
      if (!offsetTop || offsetTop !== rect.top) {
        setOffsetTop(rect.top);
      }
    }
  }, [offsetRight, offsetTop]);

  const pdfEditorMainComponent = document.getElementById(ELEMENT_FOR_COLLECTION_SLIDERS);
  useEffect(() => {
    if (pdfEditorMainComponent) {
      getRightAndTopOffsets();
    }
    if (Object.keys(pdfTemplateFields).length && !selectedFieldKey) {
      const currentPdfFields: PDFFieldType[] = Object.values(pdfTemplateFields);
      dispatch(setSelectedFieldKey(currentPdfFields[0].key));
    }
  }, [
    dispatch,
    selectedFieldKey,
    pdfEditorMainComponent,
    activeFileKey,
    getRightAndTopOffsets,
    pdfTemplateFields,
  ]);

  useEffect(() => {
    window.addEventListener('resize', getRightAndTopOffsets);
    return () => {
      window.removeEventListener('resize', getRightAndTopOffsets);
    };
  }, [getRightAndTopOffsets]);

  const changeDocumentInCollectionHandler = (fileKey: string | null) => {
    setActiveFileKey(fileKey);
    onChangeDocumentInCollection(fileKey);
    setTimeout(() => setActiveFileKey(null), 2000);
  };

  const getCollectionSlidersComponent = () => (
    offsetRight && offsetTop
      ? (
        <div className="collection-slides" style={{ left: `${offsetRight + 10}px`, top: `${offsetTop + 10}px` }}>
          {
            documents.map((document) => (
              <Tooltip key={document.fileKey} title={document.title}>
                <button
                  disabled={(activeFileKey !== document.fileKey) && Boolean(activeFileKey)}
                  type="button"
                  className={
                    `text-truncate collection-slides__button${
                      document.fileKey === pdfTemplateKey ? ' collection-slides__active' : ''
                    }`
                  }
                  onClick={() => changeDocumentInCollectionHandler(document.fileKey)}
                >
                  {
                    (activeFileKey !== document.fileKey) && Boolean(activeFileKey)
                      ? (<SimpleSpinner />)
                      : <span>{document.title}</span>
                  }
                </button>
              </Tooltip>
            ))
          }
        </div>
      )
      : null
  );

  return {
    currentPdfTemplateKey: pdfTemplateKey,
    getCollectionSlidersComponent,
  };
};

export default useCollectionSlidersInSignNow;