import { IKebabMenuItem } from 'types/KebabMenu';

export const setKebabColumnHeading = (kebabColRender: any) => (// eslint-disable-line
  [{
    name: 'kebab',
    label: ' ',
    options: {
      filter: false,
      print: false,
      sort: false,
      download: false,
      searchable: false,
      viewColumns: false,
      customBodyRender: kebabColRender,
    },
  }]
);

export const SECTIONS_KEBAB_MENU: IKebabMenuItem[] = [
  {
    title: 'Delete',
    icon: 'Delete',
    clickEvent: 'onDeleteItem',
  },
];

export const TEMPLATES_KEBAB_MENU: IKebabMenuItem[] = [
  {
    title: 'View Submissions',
    icon: 'Submissions',
    clickEvent: 'onViewSubmissions',
  },
  {
    title: 'Convert to Document',
    icon: 'Convert',
    clickEvent: 'onConvertToDocument',
  },
  {
    title: 'Convert to Form',
    icon: 'Convert',
    clickEvent: 'onConvertToForm',
  },
  {
    title: 'Copy',
    icon: 'Copy',
    clickEvent: 'onCopyTemplate',
  },
  {
    title: 'Share',
    icon: 'Share',
    clickEvent: 'onShareLink',
  },
  {
    title: 'Fill & sign',
    icon: 'Fill & sign',
    clickEvent: 'onFillSign',
  },
  {
    title: '-',
  },
  {
    title: 'Delete',
    icon: 'Delete',
    clickEvent: 'onDeleteItem',
  },
];

export const DOCUMENTS_KEBAB_MENU: IKebabMenuItem[] = [
  {
    title: 'Share',
    icon: 'Share',
    clickEvent: 'onShareLink',
  },
  {
    title: 'Fill & sign',
    icon: 'Fill & sign',
    clickEvent: 'onFillSign',
  },
  {
    title: 'In Progress',
    icon: 'In Progress',
    clickEvent: 'onSetInProgress',
  },
  {
    title: 'Cancel',
    icon: 'Cancel',
    clickEvent: 'onCancelAction',
  },
  {
    title: 'Complete',
    icon: 'Complete',
    clickEvent: 'onCompleteAction',
  },
  {
    title: '-',
  },
  {
    title: 'Delete',
    icon: 'Delete',
    clickEvent: 'onDeleteItem',
  },
];

export const USER_MANAGEMENT_MENU: IKebabMenuItem[] = [
  {
    title: 'Edit',
    icon: 'Edit',
    clickEvent: 'onEditUser',
  },
  {
    title: 'Resend Invite',
    icon: 'Resend Invite',
    clickEvent: 'onResendInvite',
  },
  {
    title: 'Disable',
    icon: 'Disable',
    clickEvent: 'onDisableUser',
  },
  {
    title: 'Activate',
    icon: 'Activate',
    clickEvent: 'onActivateUser',
  },
];

export const API_TOKENS_MENU: IKebabMenuItem[] = [
  {
    title: 'Expire',
    icon: 'Edit',
    clickEvent: 'onExpireTokenModalOpen',
  },
];

export const GROUP_MANAGEMENT_MENU: IKebabMenuItem[] = [
  {
    title: 'Change name',
    icon: 'Edit',
    clickEvent: 'onChangeGroupName',
  },
  {
    title: 'Delete',
    icon: 'Delete',
    clickEvent: 'onDeleteGroup',
  },
  {
    title: 'Add members',
    icon: 'Add',
    clickEvent: 'onAddGroupMembers',
  },
];

export const GROUP_LISTING_MANAGEMENT_MENU: IKebabMenuItem[] = [
  {
    title: 'Remove member',
    icon: 'Delete',
    clickEvent: 'onRemoveGroupMember',
  },
];