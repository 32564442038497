import { FC } from 'react';

import AccountSettingsWrapper from 'components/AccountSettings/AccountSettingsWrapper';
import APITokensSection from 'components/AccountSettings/APITokensSection';
import MondayComSection from 'components/AccountSettings/MondayComSection';
import SettingsLayout from 'components/AccountSettings/SettingsLayout';
import { useRolePermission } from 'hooks/useRolePermission';
import { INTEGRATIONS_SECTIONS, IntegrationSettingsType } from 'types/UserMenu';

const IntegrationsSettings: FC = () => {
  const { permissionUsingMondayComIntegration } = useRolePermission();

  const INTEGRATIONS_CONFIG: IntegrationSettingsType[] = [
    {
      integrationPermission: permissionUsingMondayComIntegration,
      title: INTEGRATIONS_SECTIONS.MONDAY_COM,
      IntegrationComponent: MondayComSection,
    },
    {
      integrationPermission: true,
      title: INTEGRATIONS_SECTIONS.API_TOKENS,
      IntegrationComponent: APITokensSection,
    },
  ];

  return (
    <AccountSettingsWrapper>
      {
        INTEGRATIONS_CONFIG.map(({ integrationPermission, title, IntegrationComponent }) => {
          if (integrationPermission) {
            return (
              <SettingsLayout title={title} key={title}>
                <IntegrationComponent />
              </SettingsLayout>
            );
          }
          return null;
        })
      }
    </AccountSettingsWrapper>
  );
};

export default IntegrationsSettings;