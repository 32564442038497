import { REMINDERS_PROPERTIES } from 'constants/pageSettings';
import useRemindersSettingsStructure from 'hooks/pageSettings/useRemindersSettingsStructure';
import { PageSettingsElementTypesEnum } from 'types/PageSettingsType';

const RemindersSettingsComponent = () => {
  const {
    permissionUsingReminders,
    pageSettings,
    onChangePropertyHandler,
  } = useRemindersSettingsStructure();

  return permissionUsingReminders && pageSettings
    ? (
      <div>
        <div className="text-center">Reminders</div>
        <div className="mb-2">
          <label>
            <input
              type="checkbox"
              defaultChecked={Boolean(pageSettings?.enable_email_reminder)}
              onChange={(event) => onChangePropertyHandler(
                PageSettingsElementTypesEnum.Checkbox,
                REMINDERS_PROPERTIES.ENABLE_EMAIL_REMINDER,
                event,
              )}
              disabled={false}
              className="me-2"
            />
            Send email reminder after
            <input
              className="page-settings-modal__reminder-input"
              defaultValue={Number(pageSettings?.send_email_delay_days)}
              min={1}
              type="number"
              onChange={(event) => onChangePropertyHandler(
                PageSettingsElementTypesEnum.Number,
                REMINDERS_PROPERTIES.SEND_EMAIL_DELAY_DAYS,
                event,
              )}
              disabled={false}
            />
            business days
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              defaultChecked={Boolean(pageSettings?.enable_sms_reminder)}
              onChange={(event) => onChangePropertyHandler(
                PageSettingsElementTypesEnum.Checkbox,
                REMINDERS_PROPERTIES.ENABLE_SMS_REMINDER,
                event,
              )}
              disabled={!pageSettings?.verify_identity_via_sms}
              className="me-2"
            />
            Send SMS reminder after
            <input
              className="page-settings-modal__reminder-input"
              defaultValue={Number(pageSettings?.send_sms_delay_days)}
              min={1}
              type="number"
              onChange={(event) => onChangePropertyHandler(
                PageSettingsElementTypesEnum.Number,
                REMINDERS_PROPERTIES.SEND_SMS_DELAY_DAYS,
                event,
              )}
              disabled={!pageSettings?.verify_identity_via_sms}
            />
            business days
          </label>
        </div>
      </div>
    ) : null;
};

export default RemindersSettingsComponent;