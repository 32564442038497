import ROUTES from 'constants/routes';
import { deleteSlashFromRoute } from 'utils/routeHelpers';

export const DOMAIN = window.location.origin;
export const EMBED_SCRIPT_LINK = `${DOMAIN}/static/js/embed/unicornforms-embed.min.js`;
export const URL_GEO_LOCATION = 'https://geolocation-db.com/json/';
export const URL_API_IPIFY = 'https://api.ipify.org/?format=json';
export const URL_CUSTOMER_PORTAL = 'https://account.unicornforms.com/p/login/28o8zQ3VN4lj0G43cc';

// ZenDesk constants
export const URL_ZEN_DESK_HELP = 'http://help.unicornforms.com/';
export const URL_ZEN_DESK_SNIPPET = 'https://static.zdassets.com/ekr/snippet.js?key=3dac2ead-bf6f-42f2-9ebd-819e91bcf6a6';
export const ZEN_DESK_SNIPPET_ID = 'ze-snippet';
export const ZEN_DESK_IFRAME_ID = 'launcher';

// This name is used in a local storage
export const LOCAL_USER_IP_LABEL = 'localUserIp';
/**
 * This variable was created for sending current user's IP adrress to back-end as a header.
 * It's necessary to keep headers style requirements: first letter in every word in uppercase, dash between words.
 */
export const USER_IP_LABEL = 'User-Ip';
export const TIMEZONE = 'Timezone';

// Variable is used for setting user location to localstorage
export const USER_LOCATION = 'USER_LOCATION';

export const SECTION_NAME_LABEL = 'Section Name';
export const DOCUMENT_NAME_LABEL = 'Document Name';

export const PATH_TO_DOCUMENT_LIST = '/documents';
export const PATH_TO_NOT_FOUND_PAGE = '/error404';

export const URL_PUBLIC_COLLECTION = 'public-collection';
export const URL_PUBLIC_TEMPLATE = 'public-template';
export const URL_MANAGER_TEMPLATE = 'manager-template';
export const URL_PUBLIC_DOCUMENT_LINK = 'public-document-link';
export const URL_PUBLIC_DOCUMENT = 'public-document';
export const URL_MANAGER_DOCUMENT = 'manager-document';
export const URL_PUBLIC_DOCUMENT_EXECUTED_LINK = 'public-document-executed-link';
export const URL_PUBLIC_MULTI_TEMPLATE = 'public-multi-template';
export const URL_PUBLIC_MULTI_TEMPLATE_LINK = 'public-multi-template-link';
export const URL_PUBLIC_FILL_OUT = 'fill-out';
export const URL_PUBLIC_SUCCESS_SUMMARY_PAGE = 'public-success-summary';

// We might need it in future for showing some pages without header
export const ARRAY_PAGES_WITHOUT_HEADER = [''];
export const ARRAY_PAGES_WITH_COPYRIGHTS = [
  'dashboard', 'collections', 'documents', 'PDFs', 'sections', 'forms', 'send-documents',
];
export const ROUTES_FOR_CLOSE_BUTTON_BLOCKER: string[] = [
  'editor',
  'public',
  'manager',
];
export const ARRAY_PUBLIC_RECIPIENT_PAGES = [
  URL_PUBLIC_TEMPLATE,
  URL_PUBLIC_DOCUMENT,
  URL_PUBLIC_COLLECTION,
  ROUTES.PUBLIC_COLLECTION_EXECUTED,
  URL_PUBLIC_SUCCESS_SUMMARY_PAGE,
];
export const ARRAY_PUBLIC_MANAGER_PAGES = [URL_MANAGER_TEMPLATE, URL_MANAGER_DOCUMENT];
export const EXECUTED_DOCUMENTS_ROUTES = [
  URL_PUBLIC_DOCUMENT,
  URL_MANAGER_DOCUMENT,
  deleteSlashFromRoute(ROUTES.DOCUMENTS_EXECUTED),
  deleteSlashFromRoute(ROUTES.PDFS_EXECUTED),
  deleteSlashFromRoute(ROUTES.FORM_EXECUTED),
];

// Typo
export const SAVE = 'Save';
export const OK = 'OK';
export const NEXT_DOCUMENT = 'Next Document';
export const FINISH_DOCUMENT = 'Finish Document';
export const ADD = 'Add';
export const SUBMIT = 'Submit';
export const CANCEL = 'Cancel';
export const CONFIRM = 'Confirm';
export const CLEAR = 'Clear';
export const CLOSE = 'Close';
export const DELETE = 'Delete';
export const UPDATE = 'Update';
export const RESET = 'Reset';
export const UPLOAD_IMAGE = 'Upload image here';
export const COPY = 'Copy';
export const SIGN = 'Sign';
export const NEXT = 'Next';
export const SEND = 'Send';
export const REMIND = 'Remind';
export const NEXT_ADD_ATTACHMENT = 'Next Add Attachments';
export const START_SIGNING = 'Start signing';
export const REVIEW_DOCUMENT = 'Review document';
export const LOGIN = 'Log in';
export const START_OVER = 'Start Over';
export const UNICORN_FORMS = 'UnicornForms';
export const PLEASE_RE_LOGIN = 'You have been logged out. Please re-login.';
export const DOWNLOAD = 'Download';

export const MESSAGE_AFTER_SUBMIT_FORM = 'Thank you for your submission!';

// Documents types
export const DOCUMENT_TYPE = 'document';
export const FORM_TYPE = 'form';
export const PDF_TYPE = 'pdf';
export const COLLECTION_TYPE = 'collection';
export const COLLECTION_EXECUTED_TYPE = 'collectionExecuted';
export const DOCUMENT_EXECUTED_TYPE = 'documentExecuted';
export const SECTION_TYPE = 'section';

export const REQUIRED_SYMBOL = '*';

export const CHUNK_SIZE = 5242880;

// Cookies settings
export const UNICORNS_ACCEPTANCE_TERMS_OF_USE = 'unicorns_acceptance_terms_of_use';
export const COOKIES_EXPIRATION_TIME_IN_MONTHS = 6;

export const HIDDEN_PASSWORD = '••••••••';

export const ACTIVE_USER_STATUS = 'active';
export const DISABLED_USER_STATUS = 'disabled';

// API Tokens
export const ACTIVE_TOKEN_STATUS = 'Active';
export const EXPIRED_TOKEN_STATUS = 'Expired';

// Modal settings
export enum GROUP_MANAGEMENT_MODAL_VARIANT {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum GROUP_MANAGEMENT_USER_ACTIONS {
  DELETE = 'DELETE',
  ADD = 'ADD'
}

export const DELETE_CONFIRMATION_MODAL_TEXT = 'Warning: Removing a section is a permanent action and cannot be '
  + 'restored. Are you sure you wish to remove the following section?';
export const SEARCH_INPUT_LIMIT = 10;

export const SLIDER_SCROLLABLE_ID = 'slider-scrollable-node';
export const FORM_BUILDER_CONTAINER_ID = 'form_builder_sortable';
export const FORM_BUILDER_SECTION_WRAPPER_ID = 'form-builder';

export const ACCEPT_LOGO_TYPES = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];

export const TABLET_SCREEN_WIDTH = 768;

export const enum RedirectTypeEnum {
  Create = 'Create',
  Update = 'Update',
  FillAndSign = 'FillAndSign',
  Send = 'Send',
  Single = 'Single',
  Remind = 'Remind',
}