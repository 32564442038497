import { FC, useState } from 'react';

import { ThemeProvider } from '@mui/material';
import { ROWS_PER_PAGE_BY_DEFAULT, tableActionForUpdatingSelects } from 'constants/muiTable';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableState } from 'mui-datatables';

import { APITokensRowsType, GroupListRowsType, SelectedRowsType, UserListRowsType } from 'types/Mui';

import getTableTheme from 'style/TableTheme';

interface IMUITableManagement {
  tableColumns: MUIDataTableColumnDef[];
  documentsRows: UserListRowsType | GroupListRowsType | APITokensRowsType | any;
  onRowsDelete?: (rowsDeleted: SelectedRowsType) => void | undefined;
  disableSelectableRows?: boolean;
}

const MUITableManagement: FC<IMUITableManagement> = ({
  tableColumns,
  documentsRows,
  onRowsDelete,
  disableSelectableRows = false,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_BY_DEFAULT);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [activeColumn, setActiveColumn] = useState<string | null>(null);
  const [rowsSelected, setRowsSelected] = useState<SelectedRowsType[]>([]);
  const [tableData, setTableData] = useState<MUIDataTableState>();

  return (
    <ThemeProvider theme={getTableTheme()}>
      <MUIDataTable
        columns={tableColumns}
        data={documentsRows}
        title=""
        options={{
          isRowSelectable: (dataIndex: number) => {
            if (tableData?.displayData.length) {
              const currentRows = tableData?.displayData
                .slice(rowsPerPage * currentPage, rowsPerPage * (currentPage + 1))
                .map((row) => row.dataIndex);
              return currentRows.includes(dataIndex);
            }

            return dataIndex >= rowsPerPage * currentPage && dataIndex <= rowsPerPage * (currentPage + 1) - 1;
          },
          onTableChange: (action: string, tableState: MUIDataTableState) => {
            if (tableActionForUpdatingSelects.includes(action)) {
              setTableData(tableState);
              setRowsSelected([]);
            }

            if (action === 'propsUpdate' && tableData) {
              const previousDisplayedDataIds = tableData.displayData.map((item) => item.dataIndex);
              const currentDisplayedDataIds = tableState.displayData.map((item) => item.dataIndex);

              const isArraysEqualsInLength = previousDisplayedDataIds?.length === currentDisplayedDataIds.length;
              if (activeColumn !== tableState.activeColumn) {
                setActiveColumn(tableState.activeColumn);
                setTableData(tableState);
              }
              if (!isArraysEqualsInLength) {
                setTableData(tableState);
              }
            }
          },
          selectableRowsHeader: false,
          selectableRows: disableSelectableRows ? 'none' : 'single',
          rowsSelected,
          rowsPerPage,
          onChangeRowsPerPage: (numberOfRows: number) => setRowsPerPage(numberOfRows),
          onChangePage: (currentPage: number) => setCurrentPage(currentPage),
          onRowsDelete: (rowsDeleted) => {
            if (onRowsDelete) {
              onRowsDelete(rowsDeleted);
            }
          },
        }}
      />
    </ThemeProvider>
  );
};

export default MUITableManagement;