import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Descendant } from 'slate';

import { DOCUMENT_EXECUTED_TYPE, DOCUMENT_TYPE, URL_MANAGER_TEMPLATE } from 'constants/general';
import { DOCUMENT_TAB_ID } from 'constants/tabs';
import {
  clearAttachments,
  setCreatedDocumentId,
  setCurrentDocumentActiveTab,
  setDocumentType,
  setPublicPageCurrentDocument,
} from 'store/actions/publicPages';
import { createDocument, editDocumentDetails } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { ICreateDocumentRequestObj } from 'types/Documents';
import { FieldAssignmetsType } from 'types/Editor';
import { IPublicPageGeneralStructure, IPublicPageState, PublicPageDataType } from 'types/PublicPage';
import { isManagerAssignment } from 'utils/assignmentsHelpers';
import { runAttachmentsLoading } from 'utils/attachmentHelpers';
import { getUserInfoFromPII, getUserPIIInfoArray } from 'utils/PIIAndGrouping/groupedAndPIIFieldsHelper';
import getNewDocumentDataFromTemplate from 'utils/PublicPage/getNewDocumentDataFromTemplate';
import { getEditorPageLink } from 'utils/routeHelpers';
import { updatePIIData } from 'utils/Subtypes/subtypeHelpers';
import { isDocumentType, isTemplateType } from 'utils/typeChecker';

/** Runs on Fill&Sign and Public pages */
const useSaveManagerPageHandler = (pageAssignment: FieldAssignmetsType): [
  (data: PublicPageDataType | null, id: string) => void, boolean
] => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isVisibleAttachmentLoaderManager, setAttachmentLoaderVisibilityManager] = useState<boolean>(false);
  const {
    data,
    totalAttachedFilesCounter,
    documentId,
    attachmentsQueueCompleted,
    attachments,
    structure,
  }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);
  const { piiData } = useSelector((state: RootStateType) => state.profile);
  const { documents }: IPublicPageGeneralStructure = structure;
  const isManagerFlow = isManagerAssignment(pageAssignment);

  useEffect(() => {
    dispatch(setDocumentType(
      pathname.startsWith(`/${URL_MANAGER_TEMPLATE}`) ? DOCUMENT_TYPE : DOCUMENT_EXECUTED_TYPE,
    ));
    return () => {
      dispatch(setDocumentType(null));
    };
  }, [data]);

  useEffect(() => {
    if (Boolean(totalAttachedFilesCounter) && Boolean(documentId)) {
      setAttachmentLoaderVisibilityManager(true);
      runAttachmentsLoading(String(documentId));
    }
  }, [totalAttachedFilesCounter, documentId]);

  useEffect(() => {
    if (isManagerFlow && Boolean(documentId) && (attachmentsQueueCompleted === Object.values(attachments).length)) {
      setAttachmentLoaderVisibilityManager(false);
      dispatch(setCreatedDocumentId(0));
      dispatch(clearAttachments());
      dispatch(setPublicPageCurrentDocument(null));
    }
  }, [attachmentsQueueCompleted, attachments, documentId]);

  const saveManagerPage = (data: PublicPageDataType | null, id: string) => {
    if (isTemplateType(data)) {
      const newDocumentData = getNewDocumentDataFromTemplate({
        data,
        withUserData: true,
        assignment: pageAssignment,
        mainAssignment: pageAssignment,
        historyLog: documents[0].historyLog,
      });
      if (newDocumentData) {
        const userPIIData = getUserPIIInfoArray(getUserInfoFromPII(piiData));
        newDocumentData.user_data = updatePIIData(
          userPIIData,
          newDocumentData.user_data || [],
        );
      }
      dispatch(createDocument({
        document: { ...newDocumentData, document_assignment: pageAssignment },
        isManagerPage: true,
      }));
    }
    if (isDocumentType(data)) {
      const userPIIData = getUserPIIInfoArray(getUserInfoFromPII(piiData));
      const editedDocument: ICreateDocumentRequestObj = {
        name: data.name,
        content_json: data.content_json as Descendant[],
        manager_page: true,
        user_data: userPIIData,
      };

      const redirectObject = totalAttachedFilesCounter === 0
        ? { path: getEditorPageLink(String(data?.id), data?.type, true) }
        : {};
      dispatch(editDocumentDetails({
        id,
        document: { ...editedDocument, document_assignment: pageAssignment },
        ...redirectObject,
      }));
    }
    /* There are editor and document tabs on public pages. It might be 3 (FORM_TAB, Attachments)
    But there isn't form view in manager's interface. So it'll be changed to editor tab anyway */
    dispatch(setCurrentDocumentActiveTab(DOCUMENT_TAB_ID));
  };

  return [saveManagerPage, isVisibleAttachmentLoaderManager];
};

export default useSaveManagerPageHandler;