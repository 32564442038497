import { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import MenuIcon from '@mui/icons-material/Menu';
import moment from 'moment';

import cn from 'classnames';
import MainCompanyLogo from 'components/MainCompanyLogo';
import NotificationDropDown from 'components/NotificationDropDown';
import Tooltip from 'components/Tooltip';
import TopNav from 'components/TopNav';
import UserMenu from 'components/UserMenu';
import {
  DOCUMENTS_LIMIT_TYPES,
  SUBSCRIPTION_TYPE_TRIAL,
  TRIAL_ACCOUNT_LABEL,
} from 'constants/accountSettings';
import ROUTES from 'constants/routes';
import { RootStateType } from 'store/reducers';
import { ICompanySubscription } from 'types/payment/Stripe';
import { getSubscriptionEndsMessage, getSubscriptionEndsTooltipTitle } from 'utils/AccountSettings/subscriptions';
import { getDateFromTimestamp } from 'utils/date';

const Header: FC<{ logout: () => void, isManagerPublicPage: boolean }> = ({ logout, isManagerPublicPage }) => {
  const { pathname } = useLocation();
  const [isVisibleMenu, setIsVisibleMenu] = useState<boolean>(false);
  const isAuthenticated: boolean = useSelector((state: RootStateType) => state.user.isAuthenticated);
  const subscription: ICompanySubscription | null = useSelector((state: RootStateType) => (
    state.profile?.profileInfo?.company?.subscription
  ));
  const {
    documentsLimitType,
    documentsLimitLeft,
  } = useSelector((state: RootStateType) => (
    state.profile ?? {}
  ));

  const subTrialEndDate = subscription?.stripe_subscription_trial_end_date ?? 0;
  const isTrialAccount = subscription?.status === SUBSCRIPTION_TYPE_TRIAL;

  const formattedDate = getDateFromTimestamp(subTrialEndDate);

  useEffect(() => {
    setIsVisibleMenu(false);
  }, [pathname]);

  useEffect(() => {
    if (
      isTrialAccount
      && moment(formattedDate).diff(moment(Date.now()), 'days') <= 0
    ) {
      toast.success(getSubscriptionEndsMessage(formattedDate), { autoClose: 10000 });
    }
  }, [formattedDate, isTrialAccount]);

  const onMenu = () => {
    setIsVisibleMenu(!isVisibleMenu);
  };

  return (
    <header className={`header${isManagerPublicPage ? ' public-page__header' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-2 col-sm-3 d-md-none d-flex justify-content-start align-items-center container-menu-icon">
            <MenuIcon
              className="text-white cursor-pointer menu-icon"
              onClick={onMenu}
            />
          </div>
          <div className={'col-6 col-md-2 col-xl-2 d-flex align-items-center'
            + ` justify-content-${isAuthenticated ? 'start px-0 px-sm-3' : 'center'}`
            + ' justify-content-sm-center justify-content-md-start order-md-1'}
          >
            <Link to={ROUTES.DASHBOARD} className="border-0 p-0">
              <MainCompanyLogo />
            </Link>
          </div>
          <div
            className={cn(
              'col-md-9 col-xl-8 d-md-flex order-3 order-md-2 align-items-center',
              'justify-content-center justify-content-sm-center justify-content-xl-start',
              'flex-column flex-md-row pt-4 pt-sm-0',
              isVisibleMenu ? 'd-flex mobile-menu' : 'd-none',
            )}
          >
            <TopNav />
          </div>
          {isAuthenticated && (
            <div className={'col-4 col-sm-3 col-md-1 col-xl-2 d-flex justify-content-end align-items-center'
              + ' order-md-3 my-2'}
            >
              {
                documentsLimitType && documentsLimitLeft !== null && (
                  <div className="documents-limit-message">
                    {documentsLimitLeft} credits/{
                      documentsLimitType === DOCUMENTS_LIMIT_TYPES.MONTHLY ? 'month' : 'year'
                    }
                  </div>
                )
              }
              {
                isTrialAccount && (
                  <Tooltip
                    title={getSubscriptionEndsTooltipTitle(formattedDate)}
                  >
                    <span className="text-white text-center">{TRIAL_ACCOUNT_LABEL}</span>
                  </Tooltip>
                )
              }
              <NotificationDropDown />
              <UserMenu logout={logout} />
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;