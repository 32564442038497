import { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import cn from 'classnames';
import Header from 'components/Header';
import FirstLoginModal from 'components/Modals/FirstLoginModal/FirstLoginModal';
import PoliciesModal from 'components/Modals/Policies/PoliciesModal';
import PublicPageWrapper from 'components/PublicPage/PublicPageWrapper';
import SyncLoader from 'components/SyncLoader';
import { PATH_TO_NOT_FOUND_PAGE, URL_PUBLIC_FILL_OUT } from 'constants/general';
import { NOT_FOUND_CODE } from 'constants/generalErrors';
import ROUTES from 'constants/routes';
import useAutoUpdateResources from 'hooks/AutoUpdateResources/useAutoUpdateResources';
import usePIIUserDataCleanUp from 'hooks/usePIIUserDataCleanUp';
import useRoutes from 'hooks/useRoutes';
import { logoutUser } from 'store/actions/auth';
import { getStartData } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { trackAmplitudeEvent } from 'utils/amplitude/amplitudeTrackingUtlis';
import { amplitudeInitialize } from 'utils/amplitude/amplitureInitialize';
import { DEFAULT_USER_INFO, LOGIN_EVENT } from 'utils/amplitude/amplituteConstants';
import disableCloseEvent from 'utils/BlockCloseButtonHelpers';
import createRoutes from 'utils/createRoutes';
import {
  getPendoInitializingObject,
  initializeZenDesk,
  removeZenDesk,
} from 'utils/initializeSnippets';
import { applyCloseButtonBlocker, toShowBlock } from 'utils/routeHelpers';

import 'react-toastify/dist/ReactToastify.min.css';

const App: FC = () => {
  const dispatch = useDispatch();
  const isAuthenticated: boolean = useSelector((state: RootStateType) => state.user.isAuthenticated);
  const isLoading: boolean = useSelector((state: RootStateType) => state.errorLoading.isLoading);

  usePIIUserDataCleanUp(isAuthenticated);
  useAutoUpdateResources();
  const history = useHistory();
  const location = useLocation<{ from?: string; }>();
  const pageError = useSelector((state: RootStateType) => state.errorLoading.error);
  const userInfo = useSelector((state: RootStateType) => state.profile.profileInfo);
  const fieldInFocus = useSelector((state: RootStateType) => state.editorSlate.fieldInFocus);

  const isShownHeader = toShowBlock(location.pathname, 'header');
  const isShownCopyrights = toShowBlock(location.pathname, 'copyright');
  const isPublicPage = toShowBlock(location.pathname, 'public');
  const isManagerPublicPage = toShowBlock(location.pathname, 'manager');

  const isDefaultHeader = isShownHeader && !isPublicPage;

  useEffect(() => {
    if (applyCloseButtonBlocker(location.pathname)) {
      const pathArray = location.pathname.replace('-', '/').split('/');
      window.addEventListener('beforeunload', disableCloseEvent);
      if (pathArray.includes(URL_PUBLIC_FILL_OUT)) {
        window.removeEventListener('beforeunload', disableCloseEvent);
        window.addEventListener('beforeunload', disableCloseEvent);
      }
    } else {
      window.removeEventListener('beforeunload', disableCloseEvent);
      window.removeEventListener('beforeunload', disableCloseEvent);
    }

    return () => {
      window.removeEventListener('beforeunload', disableCloseEvent);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (!isAuthenticated || isPublicPage) {
      removeZenDesk();
      amplitudeInitialize(DEFAULT_USER_INFO);
    }

    if (isAuthenticated && !isPublicPage) {
      /**
       * It's important to initialize ZenDesk only for authorized users.
       * There is no need to do that on public pages.
       */
      initializeZenDesk();
      if (userInfo) {
        // eslint-disable-next-line
        // @ts-ignore
        window.pendo.initialize(getPendoInitializingObject(userInfo));
        amplitudeInitialize(userInfo);
      }
    }
  }, [isAuthenticated, userInfo, isPublicPage]);

  useEffect(() => {
    if (userInfo && location.state?.from === ROUTES.LOGIN) {
      trackAmplitudeEvent({
        eventName: LOGIN_EVENT,
        userInfo,
      });

      history.replace({ ...history.location, state: { ...(history.location.state as object), from: null } });
    }
  }, [userInfo]);

  const currentRoutes = useRoutes();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getStartData());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && location.pathname === ROUTES.LOGIN) {
      history.push(ROUTES.DASHBOARD);
    }
    if (isAuthenticated && pageError?.status === NOT_FOUND_CODE) {
      history.push(PATH_TO_NOT_FOUND_PAGE);
    }
  }, [isAuthenticated, history, pageError, location.pathname]);

  const onLogoutHandler = () => {
    dispatch(logoutUser());
  };

  return (
    <>
      {isDefaultHeader && <Header logout={onLogoutHandler} isManagerPublicPage={isManagerPublicPage} />}
      <PublicPageWrapper
        isPublicPage={isPublicPage}
        isManagerPublicPage={isManagerPublicPage}
      >
        <main
          className={cn({
            'public-page__main': isPublicPage || isManagerPublicPage,
            main: !(isPublicPage || isManagerPublicPage),
            'field-in-focus': fieldInFocus,
          })}
          id={isPublicPage || isManagerPublicPage ? 'public-main' : 'main'}
        >
          {isLoading && <SyncLoader />}
          <Switch>
            {createRoutes(currentRoutes)}
          </Switch>
          <ToastContainer
            style={{ minWidth: 'min-content' }}
            pauseOnFocusLoss={false}
            rtl={false}
          />
        </main>
      </PublicPageWrapper>
      <FirstLoginModal />
      {isShownCopyrights && <PoliciesModal showOnlyLink />}
    </>
  );
};

export default App;