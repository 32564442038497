import { ChangeEvent, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button as ReactStrapButton } from 'reactstrap';

import Button from 'components/Base/Button';
import BaseInputField from 'components/Base/InputField';
import KebabMenu, { HandlersValueType } from 'components/KebabMenu';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import ManagementModal from 'components/Modals/UserManagementModal/ManagementModal';
import MUITableManagement from 'components/MuiTables/MUITableManagement';
import Tooltip from 'components/Tooltip';
import { COPY } from 'constants/general';
import { API_TOKENS_MENU, setKebabColumnHeading } from 'constants/KebabMenus';
import { ADMIN_ROLES } from 'constants/roles';
import useAPITokensLoader from 'hooks/APITokensSettings/useAPITokensLoader';
import { expireAPIToken, generateAPIToken, setCreatedAPIToken } from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { apiTokensColumns, getAPITokensTableData } from 'utils/dataColumns';

import 'scss/components/_managementSettings.scss';

const APITokensSection = () => {
  const dispatch = useDispatch();
  const {
    apiTokens,
    createdAPIToken,
  } = useAPITokensLoader();

  const { userRole } = useSelector((state: RootStateType) => state.profile.profileInfo);
  const { isLoading } = useSelector((state: RootStateType) => state.errorLoading);

  const [isAddTokenModalVisible, setIsAddTokenModalVisible] = useState<boolean>(false);
  const [isExpireTokenModalVisible, setIsExpireTokenModalVisible] = useState<boolean>(false);

  const [localTokenName, setLocalTokenName] = useState<string>('');
  const [localTokenJTIToExpire, setLocalTokenJTIToExpire] = useState<string>('');
  const [localTokenError, setLocalTokenError] = useState<boolean>(false);

  const onExpireTokenModalOpen = (value: { jti: string }) => {
    setIsExpireTokenModalVisible(true);
    setLocalTokenJTIToExpire(value.jti);
  };

  const onExpireTokenModalClose = () => {
    setIsExpireTokenModalVisible(false);
    setLocalTokenJTIToExpire('');
  };

  const onExpireToken = () => {
    dispatch(expireAPIToken(localTokenJTIToExpire));
    setIsExpireTokenModalVisible(false);
  };

  const kebabColActiveUser = (value: HandlersValueType) => (
    <KebabMenu
      value={value}
      menuOptions={API_TOKENS_MENU}
      methods={{
        onExpireTokenModalOpen,
      }}
    />
  );

  const onAddTokenModalClose = () => {
    setLocalTokenName('');
    setLocalTokenError(false);
    dispatch(setCreatedAPIToken(''));
    setIsAddTokenModalVisible(false);
  };

  const onSaveToken = () => {
    if (!localTokenName) {
      setLocalTokenError(true);
      return;
    }
    dispatch(generateAPIToken(localTokenName));
  };

  const onChangeLocalTokenName = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLocalTokenName(value);
    setLocalTokenError(false);
  };

  const handlerCopyToClipboard = useCallback((text: string): void => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      toast.success('Token copied');
    }
  }, []);

  const tableColumns = apiTokensColumns.concat(setKebabColumnHeading(kebabColActiveUser));

  return (
    <>
      <div className="management-settings">
        <div className="management-controls d-flex justify-content-between align-items-center">
          <Button
            onClick={() => setIsAddTokenModalVisible(true)}
            disabled={!ADMIN_ROLES.includes(userRole)}
          >
            Add Token
          </Button>
        </div>
        <div className="mt-4">
          <MUITableManagement
            disableSelectableRows
            tableColumns={tableColumns}
            documentsRows={getAPITokensTableData(apiTokens)}
          />
        </div>
      </div>
      <ManagementModal
        centered
        title="Add Token"
        isOpen={isAddTokenModalVisible}
        onClose={onAddTokenModalClose}
        onSave={createdAPIToken ? onAddTokenModalClose : onSaveToken}
        saveButtonText={createdAPIToken ? 'Close' : 'Generate Token'}
        disableSaveButton={isLoading}
        withCancelButton={!createdAPIToken}
      >
        <BaseInputField
          disabled={Boolean(createdAPIToken)}
          required
          label="Token Name"
          value={localTokenName}
          showError={localTokenError}
          helperText="Token Name is required"
          onChange={onChangeLocalTokenName}
        />
        {
          createdAPIToken && (
            <>
              <div
                className="border border-dark rounded-3 p-3 my-3 overflow-hidden d-flex flex-row justify-content-between align-items-center"
              >
                <div className="w-75">
                  <Tooltip title={createdAPIToken}>
                    <div className="text-truncate">{createdAPIToken}</div>
                  </Tooltip>
                </div>
                <ReactStrapButton
                  disabled={!createdAPIToken}
                  color="primary"
                  outline
                  className="btn-sm h-50"
                  onClick={() => handlerCopyToClipboard(createdAPIToken)}
                >
                  {COPY}
                </ReactStrapButton>
              </div>
              <div
                className="text-danger"
              >
                This token will only display once. Please copy it now and securely store it.
              </div>
            </>
          )
        }
      </ManagementModal>
      <ConfirmationModal
        isOpen={isExpireTokenModalVisible}
        onCancel={onExpireTokenModalClose}
        onConfirm={onExpireToken}
        cancelButtonText="No"
        confirmButtonText="Yes"
        description={(
          <>
            <p>You are about to expire a token.</p>
            <p>Are you sure?</p>
          </>
        )}
      />
    </>
  );
};

export default APITokensSection;