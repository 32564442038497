import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { deletePdfPartsMultiTemplate } from 'store/actions/collections';
import { cleanAssignments } from 'store/actions/editorSlate';
import { clearPdfTemplateData } from 'store/actions/pdfTemplates';
import { clearFullSignNowStore, clearSignNowStore, setIsSignNowActive } from 'store/actions/signNow';
import { RootStateType } from 'store/reducers';
import { MatchParams } from 'types/Route';
import { UseSignNowPageHookType } from 'types/signNow/base';

const useSignNowPage: UseSignNowPageHookType = () => {
  const dispatch = useDispatch();
  const { id }: MatchParams = useParams();
  const {
    files,
    documents,
    isRemindDocumentActive,
  } = useSelector((state: RootStateType) => state.signNow);
  const isSignNow = useSelector((state: RootStateType) => state.signNow.isSignNow);

  useEffect(() => {
    if (!isSignNow) {
      dispatch(setIsSignNowActive());
    }
  }, [dispatch, isSignNow]);

  useEffect(() => () => {
    dispatch(clearSignNowStore());
    dispatch(cleanAssignments());
    if (files && files.length > 1) {
      dispatch(clearFullSignNowStore());
      dispatch(deletePdfPartsMultiTemplate());
      dispatch(clearPdfTemplateData());
    }
  }, [dispatch]);

  const [initStep, setInitStep] = useState(1);

  useEffect((): void => {
    if (isRemindDocumentActive) return setInitStep(3);
    if (documents[0]?.id || id) return setInitStep(2);
    if (!files.length) return setInitStep(0);
  }, [documents[0]?.id, files.length, id, isRemindDocumentActive]);

  return {
    initStep,
  };
};

export default useSignNowPage;