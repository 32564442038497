import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  AddCircleOutline as AddCircleIcon,
  ContentCopy as ContentCopyIcon,
  DeleteOutline as DeleteIcon,
} from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Descendant, Editor as SlateEditor, Element as SlateElement, Node, Path, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import Tooltip from 'components/Tooltip';
import {
  ATTACHMENT_FIELD,
  FIELD_TYPE_VARIANTS,
  PARAGRAPH,
  RECIPIENT_ASSIGNMENT,
  SIGNING_FIELD,
} from 'constants/editors';
import { useRolePermission } from 'hooks/useRolePermission';
import { changeSelectedField, setAllAssignments, setSelectFieldError } from 'store/actions/editorSlate';
import { RootStateType } from 'store/reducers';
import { BlockFormatType, ICustomElement } from 'types/Editor';
import { getActualAssignmentsState } from 'utils/assignmentsHelpers';
import { removeAllFilesFromDropzone } from 'utils/attachmentHelpers';
import { createNameConstant } from 'utils/createNameConstant';
import { addPropertiesByType, generateNumber } from 'utils/editorFieldHelpers';
import { getIsAttachmentField } from 'utils/Fields/fieldsTypeChecker';

interface IFormFieldButtons {
  fieldKey: number;
  existingFieldMask: boolean;
  hideValue: boolean;
  setHideValue: Dispatch<SetStateAction<boolean>>;
  element?: Node | undefined;
}

const FormFieldButtons: FC<IFormFieldButtons> = ({
  fieldKey,
  existingFieldMask,
  hideValue,
  setHideValue,
  element,
}) => {
  const editor = useSlate();
  const dispatch = useDispatch();
  const { permissionUsingAttachments } = useRolePermission();
  const { assignments, selectedAssignment } = useSelector((state: RootStateType) => state.editorSlate);
  const {
    sensitive_info_access: sensitiveInfoAccess,
  } = useSelector((state: RootStateType) => state.profile.profileInfo ?? {});

  const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);
  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const handleTypeMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setTooltipOpen(false);
    setAnchorMenuEl(event.currentTarget || event.target);
  };

  const handleTypeMenuClose = () => {
    setAnchorMenuEl(null);
  };

  const getFieldPositionToInsert = (currentEditor: SlateEditor, currentElement?: Node) => {
    let insertToPath: number[] = [];
    if (currentElement) {
      const fieldPath = ReactEditor.findPath(currentEditor, currentElement);
      const parentListNode = SlateEditor.above(currentEditor, {
        match: (n) => SlateElement.isElement(n) && n.type === PARAGRAPH,
        at: fieldPath,
      });
      if (parentListNode) {
        const [, parentPath] = parentListNode;
        insertToPath = Path.next(parentPath);
      }
    }
    return insertToPath;
  };

  const insertToPath = getFieldPositionToInsert(editor, element);
  const insertAt = (insertToPath && insertToPath.length) ? { at: insertToPath } : {};

  const onAddNewFieldHandler = (dataType: BlockFormatType = PARAGRAPH) => {
    setAnchorMenuEl(null);
    const newNode: SlateElement = {
      type: PARAGRAPH,
      key: generateNumber(),
      children: [
        { text: '' },
      ],
    };

    const newFieldKey = generateNumber();
    if (dataType !== PARAGRAPH) {
      const parentElement = element as SlateElement;
      const fieldData = addPropertiesByType(
        {
          key: newFieldKey,
          type: dataType,
          assignment: selectedAssignment,
          name: 'NEW_FIELD',
          value: '',
          position: parentElement.position || 0,
          children: [{ text: 'New Field' }],
        },
        dataType,
      );
      newNode.children = [fieldData];
    }

    Transforms.insertNodes(editor, newNode, insertAt);
    if (dataType !== PARAGRAPH) {
      dispatch(changeSelectedField(newFieldKey));
    }
  };

  const onCopyFieldHandler = () => {
    const parentElement = element as SlateElement;
    if (!parentElement) return;

    const arrayText = parentElement.children.map(({ text }) => text);
    const fieldName = createNameConstant(arrayText);
    const attachmentProps = getIsAttachmentField(parentElement.type)
      ? {
        value: '',
        properties: {
          ...parentElement.properties,
          count: 0,
        },
      } : {};
    const fieldKey = generateNumber();
    const newField: SlateElement = {
      ...parentElement,
      key: fieldKey,
      name: fieldName,
      value: parentElement.type === SIGNING_FIELD ? '' : parentElement.value,
      ...attachmentProps as Partial<ICustomElement>,
    };

    dispatch(setAllAssignments(getActualAssignmentsState(
      assignments,
      '',
      newField.assignment || RECIPIENT_ASSIGNMENT,
      fieldKey,
    )));

    const newNode: SlateElement = {
      type: PARAGRAPH,
      key: generateNumber(),
      children: [
        { ...newField },
      ],
    };

    Transforms.insertNodes(editor, newNode, insertAt);
    setTimeout(() => dispatch(changeSelectedField(fieldKey)), 0);
  };

  const onDeleteCurrentFieldHandler = useCallback(() => {
    dispatch(changeSelectedField(null));
    dispatch(setSelectFieldError(false, fieldKey));
    if (!element) return;

    dispatch(setAllAssignments(getActualAssignmentsState(
      assignments,
      (element as ICustomElement).assignment,
      '',
      fieldKey,
    )));

    if ((element as ICustomElement).type === ATTACHMENT_FIELD) {
      removeAllFilesFromDropzone(fieldKey);
    }

    const fieldPath = ReactEditor.findPath(editor, element);
    Transforms.select(editor, SlateEditor.start(editor, []));
    const contentAfter = SlateEditor.after(editor, fieldPath, { unit: 'block' });
    editor.selection = null;
    if (!contentAfter || (contentAfter && contentAfter.offset === 0 && contentAfter.path[0] !== fieldPath[0])) {
      editor.findNodesAndUpdate((node: Descendant) => {
        if (node?.key === fieldKey) {
          return { text: '' };
        }
        return node;
      });
      Transforms.unwrapNodes(editor, {
        at: fieldPath,
        match: (node) => SlateElement.isElement(node) && (node.type === PARAGRAPH || !!node.assignment),
      });
    } else {
      Transforms.removeNodes(editor, {
        at: fieldPath,
        match: (node) => SlateElement.isElement(node) && node.type === PARAGRAPH,
      });
    }
  }, [editor]);

  const typeMenuId = `select-field-type-menu-${fieldKey}`;
  const addFieldButtonId = `add-field-button-${fieldKey}`;
  const additionalButtonsStyles = {
    display: 'flex',
    padding: '6px 16px',
    justifyContent: 'flex-start',
  };

  return (
    <>
      <div className="form-section__field-buttons mt-4">
        <Tooltip title="Add" open={isTooltipOpen}>
          <IconButton
            id={addFieldButtonId}
            aria-label="Add"
            size="small"
            className="p-1"
            aria-controls={anchorMenuEl ? typeMenuId : undefined}
            aria-haspopup="true"
            aria-expanded={anchorMenuEl ? 'true' : undefined}
            onClick={handleTypeMenuOpen}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
          >
            <AddCircleIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy">
          <IconButton aria-label="Copy" size="small" className="p-1" onClick={onCopyFieldHandler}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {
          existingFieldMask && sensitiveInfoAccess && (
            <Tooltip title="Hide/Reveal">
              <IconButton
                aria-label="Hide/Reveal"
                size="small"
                className="p-1"
                onClick={() => setHideValue((prevState) => !prevState)}
              >
                {hideValue ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Tooltip>
          )
        }
        <Tooltip title="Delete">
          <IconButton aria-label="Delete" size="small" className="p-1" onClick={onDeleteCurrentFieldHandler}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        aria-labelledby={addFieldButtonId}
        anchorEl={anchorMenuEl}
        id={typeMenuId}
        open={Boolean(anchorMenuEl)}
        onClose={handleTypeMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          FIELD_TYPE_VARIANTS.map((typeElement) => {
            if (typeElement.type === ATTACHMENT_FIELD && !permissionUsingAttachments) return null;
            return (
              <MenuItem
                key={typeElement.id}
                onClick={() => onAddNewFieldHandler(typeElement.type)}
                style={additionalButtonsStyles}
              >
                {typeElement.label}
              </MenuItem>
            );
          })
        }
        <MenuItem
          onClick={() => onAddNewFieldHandler(PARAGRAPH)}
          style={additionalButtonsStyles}
        >
          Paragraph
        </MenuItem>
      </Menu>
    </>
  );
};

export default React.memo(FormFieldButtons);