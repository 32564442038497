import { useSelector } from 'react-redux';

import DashboardDropzone from 'components/Dashboard/DashboardDropzone';
import GlobalNavigationItem from 'components/GlobalNavigation/GlobalNavigationItem';
import {
  globalNavigationCollections,
  globalNavigationDocuments,
  globalNavigationForms,
  globalNavigationPdfs,
  IGlobalNavigation,
} from 'constants/globalNavigation';
import { useRolePermission } from 'hooks/useRolePermission';
import { RootStateType } from 'store/reducers';

const renderGlobalNavigationItems = (routeConfig: IGlobalNavigation[]) => (
  routeConfig.map((route: IGlobalNavigation) => (
    <GlobalNavigationItem
      key={route.title}
      title={route.title}
      linkTitle={route.linkTitle}
      linkPath={route.linkPath}
      Icon={route.Icon}
      globalPath={route.globalPath}
      documentLinkTitle={route.documentLinkTitle}
      documentLinkPath={route.documentLinkPath}
    />
  ))
);

const GlobalNavigation = () => {
  const {
    permissionUsingDocumentEditor,
    permissionUsingPDFEditor,
    permissionUsingForms,
    permissionUsingCollections,
    permissionUsingSignNow,
    isUserReadOnly,
  } = useRolePermission();
  let navigationItems: IGlobalNavigation[] = [];

  if (permissionUsingDocumentEditor) {
    navigationItems = navigationItems.concat(globalNavigationDocuments);
  }

  if (permissionUsingPDFEditor) {
    navigationItems = navigationItems.concat(globalNavigationPdfs);
  }

  if (permissionUsingForms) {
    navigationItems = navigationItems.concat(globalNavigationForms);
  }

  if (permissionUsingCollections) {
    navigationItems = navigationItems.concat(globalNavigationCollections);
  }

  const {
    first_name: firstName,
    last_name: lastName,
  } = useSelector((state: RootStateType) => state.profile.profileInfo);
  return (
    !isUserReadOnly
      ? (
        <div className="global-navigation d-none d-sm-block">
          <div className="container w-75 mx-auto">
            <div className="row justify-content-center">
              <div className="col-12 d-flex justify-content-start p-0">
                <p className="global-navigation_text m-0">Welcome back, {firstName} {lastName}!</p>
              </div>
            </div>
            <div className="row justify-content-evenly pb-4">
              {renderGlobalNavigationItems(navigationItems)}
            </div>
            {
              permissionUsingSignNow && (<DashboardDropzone />)
            }
          </div>
        </div>
      )
      : null
  );
};

export default GlobalNavigation;