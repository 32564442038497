import { toast } from 'react-toastify';
import { put, select, takeLatest } from 'redux-saga/effects';

import { SUCCESS_CODE } from 'constants/generalErrors';
import ROUTES from 'constants/routes';
import { PROFILE_SECTIONS } from 'constants/UserMenu';
import {
  checkResetPasswordLink,
  createResetPasswordLink,
  login,
  logout,
  register,
  resetPassword,
} from 'services/api';
import { history } from 'services/history';
import {
  AUTHENTICATE_USER,
  CHECK_RESET_PASSWORD,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_LINK,
  SET_LOADED,
} from 'store/actions/actionTypes';
import {
  clearStripeStore,
  setStripePaidLink,
  setStripeTrialDaysLeft,
  setSubscriptionIsEnded,
} from 'store/actions/payment';
import { setAPITokens, setFirstLoginParameter } from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { ILoginResponse } from 'types/Auth';
import {
  deleteAuthInStorage,
  setAuthInStorage,
} from 'utils/localStorage';

const registerUser = function* ({ payload }: any) {
  yield register(payload);
  yield login(payload);
  yield put({ type: AUTHENTICATE_USER, payload: true });
};

const loginUser = function* ({ payload }: any) {
  const response: ILoginResponse = yield login(payload);
  if (response.subscription_is_ended) {
    yield put(setSubscriptionIsEnded(response.subscription_is_ended));
    yield put(setStripePaidLink(response.stripe_link));
  } else {
    yield setAuthInStorage(response);
    yield put({ type: AUTHENTICATE_USER, payload: true });

    if (response.first_login) {
      yield put(setFirstLoginParameter(response.first_login));
      if (response.stripe_link && response.trial_days_left) {
        yield put(setStripePaidLink(response.stripe_link));
        yield put(setStripeTrialDaysLeft(response.trial_days_left));
      }
    } else if (response.trial_days_left !== undefined && response.stripe_link) {
      toast.info(
        `You have ${response.trial_days_left <= 0 ? 'less than day' : `${response.trial_days_left} days`
        } left in your trial. Please update your billing information by clicking this message`,
        { onClick: () => window.location.replace(response.stripe_link) },
      );
    }

    if (payload.redirectAfterLogin) {
      let redirectTo = ROUTES.DASHBOARD;
      if (payload.backToLink?.length) {
        redirectTo = payload.backToLink;
      }
      yield history.push(redirectTo, { from: ROUTES.LOGIN });
    }
  }
};

const logoutUser = function* () {
  const userConfirmedAtDate: string | null = yield select(
    (state: RootStateType) => state.profile.profileInfo?.confirmed_at,
  );
  history.replace({ ...history.location, state: { logout: true } });

  const response: IResponseOfRequest<{ message: string, logout: boolean }> = yield logout();
  if (response && response.status === 200 && response.data.logout) {
    yield put({ type: AUTHENTICATE_USER, payload: false });
    yield deleteAuthInStorage();
    yield put(clearStripeStore());
    yield put(setAPITokens(null));
    yield history.push(ROUTES.LOGIN, { from: PROFILE_SECTIONS.LOGOUT });
    if (userConfirmedAtDate) {
      toast.success('You have been logged out successfully');
    }
  }
};

const createResetLink = function* ({ payload }: { payload: { email: string } }) {
  yield createResetPasswordLink(payload);
  yield put({ type: SET_LOADED, payload: true });
};

const checkResetLink = function* ({ payload }: { payload: string }) {
  yield checkResetPasswordLink(payload);
};

const resetUserPassword = function* ({ payload }: { payload: { linkId: string, password: string } }) {
  const response: IResponseOfRequest<{ message: string }> = yield resetPassword(payload.linkId, payload.password);
  if (response && response.status === SUCCESS_CODE) {
    toast.success(response.data?.message);
    yield history.push(ROUTES.LOGIN);
  }
};

export default [
  takeLatest(LOGIN_USER, safe(loginUser, false, true, true)),
  takeLatest(REGISTER_USER, safe(registerUser)),
  takeLatest(LOGOUT_USER, safe(logoutUser, true, true, false, true)),
  takeLatest(RESET_PASSWORD_LINK, safe(createResetLink, false, false, true)),
  takeLatest(CHECK_RESET_PASSWORD, safe(checkResetLink)),
  takeLatest(RESET_PASSWORD, safe(resetUserPassword)),
];