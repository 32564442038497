import { useSelector } from 'react-redux';

import { MANAGER_ASSIGNMENT, READ_ONLY_ASSIGNMENT, RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { ROLES } from 'constants/roles';
import {
  HEADER_TABS_COLLECTIONS,
  HEADER_TABS_DASHBOARD,
  HEADER_TABS_DOCUMENTS,
  HEADER_TABS_FORMS,
  HEADER_TABS_PDFS,
  HEADER_TABS_SECTIONS,
  PUBLIC_HEADER_TABS,
} from 'constants/tabs';
import {
  adminsRoutes,
  basicDocumentsRoutes,
  basicFormsRoutes,
  basicPDFRoutes,
  basicRoutes,
  collectionsRoutes,
  companyBrandingRoute,
  extendedDocumentsRoutes,
  extendedFormsRoutes,
  extendedPDFRoutes,
  extendedRoutes,
  groupManagementRoutes,
  limitedRoutes,
  pageNotFoundRoute,
  publicRoutes,
  sectionsRoutes,
  signNowRoutes,
} from 'routes';
import { RootStateType } from 'store/reducers';
import { IRole, NameRoleType } from 'types/Auth';
import { IRolePermission } from 'types/Route';

export type ReturnedSortedRoles = (NameRoleType | undefined)[]

const sortedRoles = (roles: IRole[]): ReturnedSortedRoles => {
  const ADMIN = roles.find((role) => role.name === ROLES.ADMIN)?.name;
  const SITE_ADMIN = roles.find((role) => role.name === ROLES.SITE_ADMIN)?.name;
  const USER = roles.find((role) => role.name === ROLES.USER)?.name;
  const READ_ONLY = roles.find((role) => role.name === ROLES.READ_ONLY)?.name;
  const newRoles = [ADMIN, SITE_ADMIN, USER, READ_ONLY];

  return newRoles.filter((role) => !!role);
};

export const useRolePermission = (): IRolePermission => {
  const isAuthenticated = useSelector((state: RootStateType) => state.user.isAuthenticated);
  const companySettings = useSelector((state: RootStateType) => state.profile.profileInfo?.company?.settings);
  const roles = useSelector((state: RootStateType) => state.profile.profileInfo?.roles);
  const userRoles = sortedRoles(roles || []);

  if (!isAuthenticated) {
    const allPossibleRoutes = [
      ...basicRoutes,
      ...extendedRoutes,
      ...adminsRoutes,
      ...basicDocumentsRoutes,
      ...extendedDocumentsRoutes,
      ...basicFormsRoutes,
      ...extendedFormsRoutes,
      ...basicPDFRoutes,
      ...extendedPDFRoutes,
      ...sectionsRoutes,
      ...collectionsRoutes,
    ];

    return {
      banChangeOrganization: true,
      permissionUsingSections: false,
      /**
       * Old sectins aren't used now. All documents are created from SectionsLight.
       * It means, that unauthenticated users always work with SectionsLight. At least now.
       * There are some conditions on public pages, which depend on permissionUsingSectionsLight.
       */
      permissionUsingSectionsLight: true,
      permissionUsingStates: false,
      permissionChangeApiVars: false,
      permissionUsingCollections: false,
      permissionUsingForms: false,
      permissionUsingFormBuilder: false,
      permissionUsingBulkSend: false,
      permissionUsingEmbed: false,
      permissionUsingShareLink: false,
      permissionUsingGroupManagement: false,
      permissionUsingBatchExport: false,
      routesPermission: publicRoutes.concat(pageNotFoundRoute),
      routesForAuthorizedUsers: allPossibleRoutes,
      headerTabsPermission: PUBLIC_HEADER_TABS,
      userRoleAssignments: RECIPIENT_ASSIGNMENT,
      isUserReadOnly: false,
      permissionUsingWlSendgrid: false,
      permissionUsingBatchCSVSend: false,
      permissionUsingTableField: false,
      permissionUsingPDFFormBuilder: false,
      permissionUsingDocumentEditor: false,
      permissionUsingPDFEditor: false,
      maxTemplatesNumber: null,
      enableDocumentsAutoLock: false,
      enableDataReuse: false,
      permissionUsingRememberFormProgress: false,
      permissionUsingCompanyBranding: false,
      permissionUsingMondayComIntegration: false,
      permissionUsingSignNow: false,
      permissionUsingRemoveBranding: false,
      permissionUsingNom151: false,
      permissionUsingFieldMasking: false,
      permissionUsingGroupFields: false,
      permissionUsingSmsValidation: false,
      permissionUsingReminders: false,
      permissionUsingCustomDomain: false,
      permissionUsingAttachments: false,
    };
  }

  const enableSections: boolean = companySettings?.enable_sections || false;
  const enableCollections: boolean = companySettings?.enable_collections || false;
  const enableForms: boolean = companySettings?.enable_forms || false;
  const enableBulkSend: boolean = companySettings?.enable_bulk_send || false;
  const enableShareLink: boolean = companySettings?.enable_share_link || false;
  const enableEmbed: boolean = companySettings?.enable_embed || false;
  const enableBatchExport: boolean = companySettings?.enable_batch_export || false;
  const enableSectionsLight: boolean = companySettings?.enable_sections_light || false;
  const enableStates: boolean = companySettings?.enable_states || false;
  const enableGroupManagement: boolean = companySettings?.enable_group_management || false;
  const enableFormBuilder: boolean = companySettings?.enable_form_builder || false;
  const enableWlSendgrid: boolean = companySettings?.enable_wl_sendgrid || false;
  const enableBatchCSVSend: boolean = companySettings?.enable_batch_csv_send || false;
  const enableTableField: boolean = companySettings?.enable_table_field || false;
  const enablePDFFormBuilder: boolean = companySettings?.enable_pdf_form_builder || false;
  const enableDocumentEditor: boolean = companySettings?.enable_document_editor || false;
  const enablePDFEditor: boolean = companySettings?.enable_pdf_editor || false;
  const maxTemplatesNumber: number | null = companySettings?.max_templates || null;
  const enableDocumentsAutoLock: boolean = companySettings?.enable_auto_lock || false;
  const enableDataReuse: boolean = companySettings?.enable_data_reuse || false;
  const enableRememberFormProgress: boolean = companySettings?.enable_remember_form_progress || false;
  const enableCompanyBranding: boolean = companySettings?.enable_branding || false;
  const enableMondayComIntegration: boolean = companySettings?.enable_monday_com_integration || false;
  const enableSignNow: boolean = companySettings?.enable_signnow || false;
  const enableRemoveBranding: boolean = companySettings?.enable_remove_branding || false;
  const enableNom151: boolean = companySettings?.enable_nom151 || false;
  const enableFieldMasking: boolean = companySettings?.enable_field_masking || false;
  const enableGroupFields: boolean = companySettings?.enable_group_fields || false;
  const enableSmsValidation: boolean = companySettings?.enable_sms_validation || false;
  const enableReminders: boolean = companySettings?.enable_reminders || false;
  const enableCustomDomain: boolean = companySettings?.enable_custom_domain || false;
  const enableAttachments: boolean = companySettings?.enable_attachments || false;

  let allowedRoutes = basicRoutes.concat(extendedRoutes);
  let headerTabs: string[] = HEADER_TABS_DASHBOARD;

  if (enableDocumentEditor) {
    allowedRoutes = allowedRoutes.concat(basicDocumentsRoutes, extendedDocumentsRoutes);
    headerTabs = headerTabs.concat(HEADER_TABS_DOCUMENTS);
  }

  if (enablePDFEditor) {
    allowedRoutes = allowedRoutes.concat(basicPDFRoutes, extendedPDFRoutes);
    headerTabs = headerTabs.concat(HEADER_TABS_PDFS);
  }

  if (enableForms) {
    allowedRoutes = allowedRoutes.concat(basicFormsRoutes, extendedFormsRoutes);
    headerTabs = headerTabs.concat(HEADER_TABS_FORMS);
  }

  if (enableSections) {
    allowedRoutes = allowedRoutes.concat(sectionsRoutes);
    headerTabs = headerTabs.concat(HEADER_TABS_SECTIONS);
  }

  if (enableCollections) {
    allowedRoutes = allowedRoutes.concat(collectionsRoutes);
    headerTabs = headerTabs.concat(HEADER_TABS_COLLECTIONS);
  }
  if (enableSignNow) {
    allowedRoutes = allowedRoutes.concat(signNowRoutes);
  }

  let adminRoutes = [...allowedRoutes, ...adminsRoutes];
  if (enableGroupManagement) {
    adminRoutes = adminRoutes.concat(groupManagementRoutes);
  }
  if (enableCompanyBranding) {
    adminRoutes = adminRoutes.concat(companyBrandingRoute);
  }

  switch (userRoles[0]) {
    case ROLES.ADMIN:
    case ROLES.SITE_ADMIN:
      return {
        banChangeOrganization: false,
        permissionUsingSections: enableSections,
        permissionUsingSectionsLight: enableSectionsLight,
        permissionUsingStates: enableStates,
        permissionUsingCollections: enableCollections,
        permissionUsingForms: enableForms,
        permissionUsingBulkSend: enableBulkSend,
        permissionUsingEmbed: enableEmbed,
        permissionUsingShareLink: enableShareLink || enableEmbed,
        permissionUsingBatchExport: enableBatchExport,
        permissionUsingGroupManagement: enableGroupManagement,
        permissionUsingFormBuilder: enableFormBuilder,
        permissionChangeApiVars: true,
        routesPermission: adminRoutes.concat(pageNotFoundRoute),
        headerTabsPermission: headerTabs,
        userRoleAssignments: MANAGER_ASSIGNMENT,
        isUserReadOnly: false,
        permissionUsingWlSendgrid: enableWlSendgrid,
        permissionUsingBatchCSVSend: enableBatchCSVSend,
        permissionUsingTableField: enableTableField,
        permissionUsingPDFFormBuilder: enablePDFFormBuilder,
        permissionUsingDocumentEditor: enableDocumentEditor,
        permissionUsingPDFEditor: enablePDFEditor,
        maxTemplatesNumber,
        enableDocumentsAutoLock,
        enableDataReuse,
        permissionUsingRememberFormProgress: enableRememberFormProgress,
        permissionUsingCompanyBranding: enableCompanyBranding,
        permissionUsingMondayComIntegration: enableMondayComIntegration,
        permissionUsingRemoveBranding: enableRemoveBranding,
        permissionUsingSignNow: enableSignNow,
        permissionUsingNom151: enableNom151,
        permissionUsingFieldMasking: enableFieldMasking,
        permissionUsingGroupFields: enableGroupFields,
        permissionUsingSmsValidation: enableSmsValidation,
        permissionUsingReminders: enableReminders,
        permissionUsingCustomDomain: enableCustomDomain,
        permissionUsingAttachments: enableAttachments,
      };
    case ROLES.USER:
      return {
        banChangeOrganization: true,
        permissionUsingSections: enableSections,
        permissionUsingSectionsLight: enableSectionsLight,
        permissionUsingStates: enableStates,
        permissionUsingCollections: enableCollections,
        permissionUsingFormBuilder: enableFormBuilder,
        permissionUsingForms: enableForms,
        permissionUsingBulkSend: enableBulkSend,
        permissionUsingEmbed: enableEmbed,
        permissionUsingShareLink: enableShareLink || enableEmbed,
        permissionUsingBatchExport: enableBatchExport,
        permissionUsingGroupManagement: false,
        permissionChangeApiVars: false,
        routesPermission: allowedRoutes.concat(pageNotFoundRoute),
        headerTabsPermission: headerTabs,
        userRoleAssignments: MANAGER_ASSIGNMENT,
        isUserReadOnly: false,
        permissionUsingWlSendgrid: false,
        permissionUsingBatchCSVSend: enableBatchCSVSend,
        permissionUsingTableField: enableTableField,
        permissionUsingPDFFormBuilder: enablePDFFormBuilder,
        permissionUsingDocumentEditor: enableDocumentEditor,
        permissionUsingPDFEditor: enablePDFEditor,
        maxTemplatesNumber,
        enableDocumentsAutoLock,
        enableDataReuse,
        permissionUsingRememberFormProgress: enableRememberFormProgress,
        permissionUsingCompanyBranding: false,
        permissionUsingMondayComIntegration: false,
        permissionUsingRemoveBranding: false,
        permissionUsingSignNow: enableSignNow,
        permissionUsingNom151: enableNom151,
        permissionUsingFieldMasking: enableFieldMasking,
        permissionUsingGroupFields: enableGroupFields,
        permissionUsingSmsValidation: enableSmsValidation,
        permissionUsingReminders: enableReminders,
        permissionUsingCustomDomain: false,
        permissionUsingAttachments: enableAttachments,
      };
    case ROLES.READ_ONLY:
      return {
        banChangeOrganization: true,
        permissionUsingSections: false,
        permissionUsingSectionsLight: false,
        permissionUsingStates: false,
        permissionUsingCollections: false,
        permissionUsingFormBuilder: false,
        permissionUsingForms: false,
        permissionUsingBulkSend: false,
        permissionUsingEmbed: false,
        permissionUsingShareLink: false,
        permissionUsingBatchExport: false,
        permissionUsingGroupManagement: false,
        permissionChangeApiVars: false,
        routesPermission: basicRoutes.concat(
          basicDocumentsRoutes,
          basicPDFRoutes,
          basicFormsRoutes,
          publicRoutes,
          pageNotFoundRoute,
        ),
        headerTabsPermission: HEADER_TABS_DASHBOARD,
        userRoleAssignments: READ_ONLY_ASSIGNMENT,
        isUserReadOnly: true,
        permissionUsingWlSendgrid: false,
        permissionUsingBatchCSVSend: false,
        permissionUsingTableField: false,
        permissionUsingPDFFormBuilder: false,
        permissionUsingDocumentEditor: false,
        permissionUsingPDFEditor: false,
        maxTemplatesNumber: null,
        enableDocumentsAutoLock: false,
        enableDataReuse: false,
        permissionUsingRememberFormProgress: false,
        permissionUsingCompanyBranding: false,
        permissionUsingMondayComIntegration: false,
        permissionUsingRemoveBranding: false,
        permissionUsingSignNow: false,
        permissionUsingNom151: false,
        permissionUsingFieldMasking: false,
        permissionUsingGroupFields: false,
        permissionUsingSmsValidation: false,
        permissionUsingReminders: false,
        permissionUsingCustomDomain: false,
        permissionUsingAttachments: false,
      };
    default:
      return {
        banChangeOrganization: true,
        permissionUsingSections: false,
        permissionUsingSectionsLight: false,
        permissionUsingStates: false,
        permissionUsingCollections: false,
        permissionUsingForms: false,
        permissionUsingBulkSend: false,
        permissionUsingEmbed: false,
        permissionUsingShareLink: false,
        permissionUsingBatchExport: false,
        permissionUsingGroupManagement: false,
        permissionChangeApiVars: false,
        permissionUsingFormBuilder: false,
        routesPermission: limitedRoutes.concat(pageNotFoundRoute),
        headerTabsPermission: HEADER_TABS_DOCUMENTS,
        userRoleAssignments: RECIPIENT_ASSIGNMENT,
        isUserReadOnly: false,
        permissionUsingWlSendgrid: false,
        permissionUsingBatchCSVSend: false,
        permissionUsingTableField: false,
        permissionUsingPDFFormBuilder: false,
        permissionUsingDocumentEditor: false,
        permissionUsingPDFEditor: false,
        maxTemplatesNumber: null,
        enableDocumentsAutoLock: false,
        enableDataReuse: false,
        permissionUsingRememberFormProgress: false,
        permissionUsingCompanyBranding: false,
        permissionUsingMondayComIntegration: false,
        permissionUsingRemoveBranding: false,
        permissionUsingSignNow: false,
        permissionUsingNom151: false,
        permissionUsingFieldMasking: false,
        permissionUsingGroupFields: false,
        permissionUsingSmsValidation: false,
        permissionUsingReminders: false,
        permissionUsingCustomDomain: false,
        permissionUsingAttachments: false,
      };
  }
};