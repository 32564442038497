import { InputType } from 'reactstrap/types/lib/Input';

import { FieldGroupByType } from 'types/Editor';

export enum USER_MANAGEMENT_MODAL_STATUS {
  EDIT = 'EDIT',
  ADD = 'ADD',
  SEATS_LIMIT_FULL = 'SEATS_LIMIT_FULL',
  CHANGE_ACTIVE_STATUS = 'CHANGE_ACTIVE_STATUS',
}

export type UserListGetterType = {
  id: number;
  active: boolean;
  creation_date: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  sensitive_info_access: boolean;
  [key: string]: number | boolean | string;
}

export interface IUserData {
  first_name: string;
  last_name: string;
  email: string;
}

export type PIIDataType = {
  subtype?: string;
  value: string;
  groupBy?: FieldGroupByType;
  groupByKey?: string;
  filterName?: string;
};

export type PIIUserDataType = Record<string, string>;

export type CreateUserType = IUserData & Record<string, string>;

export type ModalUserType = UserListGetterType | CreateUserType | null;

export type UsersGetterType = {
  count: number;
  data: {
    user_list: UserListGetterType[],
    users_seats: number;
  }
}

export type UpdateUserInfoType = {
  fieldsToUpdate: UserListGetterType;
  status: USER_MANAGEMENT_MODAL_STATUS;
}

export type UserListStoreType = {
  users: Array<UserListGetterType>;
  usedSeats: number;
  totalUsersSeats: number;
  loading: {
    wasLoaded: boolean;
  }
}

export interface IUserManagementFields {
  key: string;
  label: string;
  type: InputType;
  value: string;
  required?: boolean;
  errorMessage?: string;
}

export type GroupType = {
  id: number;
  company_id: number;
  creation_date: string;
  modification_date: string;
  name: string;
  users_count: number;
}

export type GroupUserType = {
  group_id: number;
  user_id: number;
  user: UserListGetterType;
}

export type GroupListingType = GroupType & {
  users: GroupUserType[];
}

export interface IUserPersonalDataObject {
  save_signature: boolean;
  signature_path: string | null;
  image_data?: string;
}

export interface IUserPersonalSignatureObject {
  save_signature?: boolean;
  image_data: string;
}

export type ProfileInfoType = {
  active: boolean;
  // TODO create type for company object
  company: any;
  company_id: number;
  confirmed_at?: Date;
  creation_date: Date;
  modification_date: Date;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  id: number;
  roles: {
    description: string;
    id: number;
    name: string;
  }[];
  userRole: string;
}

export type PIIDataKeysValues = Record<string, {
  options: string[];
  updated: boolean;
}>;

export type APIToken = {
  jti: string;
  creation_date: string;
  expiration_date: string;
  description: string;
  blacklisted: boolean;
};