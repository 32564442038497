import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getAPITokensPerCompany } from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { APIToken } from 'types/userProfile';

const useAPITokensLoader = (): { apiTokens: APIToken[], createdAPIToken: string } => {
  const dispatch = useDispatch();
  const { apiTokens, createdAPIToken } = useSelector((state: RootStateType) => state.profile ?? {});

  useEffect(() => {
    if (!apiTokens) {
      dispatch(getAPITokensPerCompany());
    }
  }, [dispatch, apiTokens]);

  return {
    apiTokens: apiTokens || [],
    createdAPIToken,
  };
};

export default useAPITokensLoader;