import { ChangeEvent, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { REMINDERS_DELAYS_IN_DAYS, REMINDERS_PROPERTIES } from 'constants/pageSettings';
import { useRolePermission } from 'hooks/useRolePermission';
import { setPageSettingValueByKey } from 'store/actions/pageSettings';
import { RootStateType } from 'store/reducers';
import { PageSettingsElementTypesEnum, PageSettingsType } from 'types/PageSettingsType';
import { getPageSettings } from 'utils/PageSettings';

const useRemindersSettingsStructure = () => {
  const dispatch = useDispatch();
  const { permissionUsingReminders } = useRolePermission();
  const pageSettings: PageSettingsType | null = useSelector((state: RootStateType) => getPageSettings(state));

  useEffect(() => {
    if (!pageSettings) {
      dispatch(setPageSettingValueByKey({
        key: REMINDERS_PROPERTIES.ENABLE_EMAIL_REMINDER,
        value: true,
      }));
      dispatch(setPageSettingValueByKey({
        key: REMINDERS_PROPERTIES.SEND_EMAIL_DELAY_DAYS,
        value: REMINDERS_DELAYS_IN_DAYS.EMAIL_DELAY,
      }));
      dispatch(setPageSettingValueByKey({
        key: REMINDERS_PROPERTIES.SEND_SMS_DELAY_DAYS,
        value: REMINDERS_DELAYS_IN_DAYS.SMS_DELAY,
      }));
    }
  }, [dispatch, pageSettings]);

  const onChangePropertyHandler = (
    type: PageSettingsElementTypesEnum,
    propKey: string,
    { target }: ChangeEvent<HTMLInputElement>,
  ) => {
    let value;
    if (type === PageSettingsElementTypesEnum.Checkbox) {
      value = target.checked;
    } else {
      value = Number(target.value);
    }
    dispatch(setPageSettingValueByKey({ key: propKey, value }));
  };

  return {
    permissionUsingReminders,
    pageSettings,
    onChangePropertyHandler,
  };
};

export default useRemindersSettingsStructure;