import ENDPOINT from 'constants/endpoints';
import { IResponseOfRequest } from 'types/Api';
import { axiosInstance } from 'utils/axiosInstance';

export const getAPITokensPerCompanyRequest = async (): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.get(`${ENDPOINT.SYSTEM_TOKEN}`)
);

export const generateAPITokenRequest = async (data: { description: string }): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.post(`${ENDPOINT.SYSTEM_TOKEN}`, data)
);

export const expireAPITokenRequest = async (data: { tokenJTI: string }): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.patch(`${ENDPOINT.SYSTEM_TOKEN}`, data)
);

export default undefined;